import { ListingContainer, ListingHeader, Heading } from './employeeListing.styles';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { EmployeesTable } from 'components/Table/EmployeesListTable/EmployeesListTable';

export const EmployeeListing = () => {
  const navigate = useNavigate();
  return (
    <ListingContainer>
      <ListingHeader>
        <ActionButton
          onClick={() => navigate('/people/add')}
          size="medium"
          title="Add Employee"
          hidden={false}
        />
      </ListingHeader>
      <ContentContainer>
        <EmployeesTable />
      </ContentContainer>
    </ListingContainer>
  );
};
