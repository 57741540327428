import { object, string } from 'yup';

export const employeeSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  dateOfBirth: string().required(),
  line1: string().required(),
  city: string().required(),
  state: string().required(),
  postalCode: string().required(),
  workplaceId: string().required(),
  startDate: string().required(),
  type: string().required(),
  amount: string().required(),
  email: string().email().required(),
}).required();

export const updateEmployeeSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  dateOfBirth: string().required(),
  line1: string().required(),
  city: string().required(),
  state: string().required(),
  postalCode: string().required(),
  workplaceId: string().required(),
}).required();
