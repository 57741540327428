import { useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentCompany } from 'features/company/slice';
import { Company } from 'features/company/types';
import { Modal } from 'components/Modal/Modal';
import { ActionButton } from 'components/ActionButton/actionButton';
import { UpdateCompanyInfoForm } from 'components/UpdateCompanyInfoForm/UpdateCompanyInfoForm';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import { formatPhoneNumber } from 'lib/utils/formatPhoneNumber';
import {
  TabContainer,
  TabHeader,
  TabContent,
  ItemContainer,
  ItemLabel,
  ItemValue,
} from '../../../tabMenu.styles';
import { CloseButton } from './basicInfomation.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';

export const BasicInformation = () => {
  const company: Company | null = useAppSelector(selectCurrentCompany);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFormSubmit = () => {
    handleCloseModal();
  };

  return (
    <TabContainer>
      <TabHeader type="company">
        <ActionButton onClick={handleOpenModal} size="medium" title="Edit" hidden={false} />
      </TabHeader>
      {company && (
        <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <UpdateCompanyInfoForm
            id={company.id}
            companyDetails={company}
            handleFormSubmit={handleFormSubmit}
          />
          <CloseButton icon={<CloseIcon />} onClick={handleCloseModal} />
        </Modal>
      )}
      <ContentContainer contentAlign="left">
        <TabContent>
          {company ? (
            <>
              <ItemContainer>
                <ItemLabel>Legal Name</ItemLabel>
                <ItemValue>{company.providerAttributes.legalName}</ItemValue>
              </ItemContainer>
              <ItemContainer>
                <ItemLabel>Company Address</ItemLabel>
                <ItemValue>
                  {company.providerAttributes.address.line1}
                  <br />
                  {`${company.providerAttributes.address.city}, ${company.providerAttributes.address.state}`}
                  <br />
                  {company.providerAttributes.address.postalCode}
                </ItemValue>
              </ItemContainer>
              <ItemContainer>
                <ItemLabel>Phone Number</ItemLabel>
                <ItemValue>{formatPhoneNumber(company.providerAttributes.phone)}</ItemValue>
              </ItemContainer>
            </>
          ) : (
            <div>No company data</div>
          )}
        </TabContent>
      </ContentContainer>
    </TabContainer>
  );
};
