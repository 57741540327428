import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CheckboxInput = styled.input`
  position: relative;
  top: 2px;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  margin: 0 5px 0 0;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.border2};

  &:disabled {
    background-color: ${(props) => props.theme.colors.gray0};
    cursor: not-allowed;
  }

  &:checked {
    background-color: ${(props) => props.theme.colors.checkbox};
  }

  &:before {
    content: '';
    display: block;
  }

  &:checked:before {
    width: 4px;
    height: 9px;
    margin: 1px 5px;
    border-bottom: 2px solid ${(props) => props.theme.colors.black};
    border-right: 2px solid ${(props) => props.theme.colors.black};
    transform: rotate(45deg);
  }
`;

export const CheckboxLabel = styled.label<{ disabled?: boolean }>`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: 16px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
