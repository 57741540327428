import { StyledTable, Thead, Tr, Th, Tbody, EmptyMessage, Button } from '../Table.styles';
import {
  Td,
  DraftStatus,
  PendingStatus,
  ProcessingStatus,
  FailedStatus,
  PartiallyPaidStatus,
  PaidStatus,
} from './Table.styles';
import { Payroll, PayrollStatus } from 'lib/fetchCompanyPayrolls';
import { reopenPayroll } from 'lib/reopenPayroll';
import { toast } from 'react-toastify';
import { formatName } from 'lib/utils/formatName';
import { formatDate } from 'lib/utils/formatDate';

export type TableProps = {
  payrolls: Payroll[];
  onOpenPayrollModal?: () => void;
};

export const Table = ({ payrolls, onOpenPayrollModal }: TableProps) => {
  if (payrolls.length === 0) {
    return <EmptyMessage>No payrolls found. Please check back later.</EmptyMessage>;
  }

  const handleReOpenPayroll = async (providerId: string) => {
    try {
      await reopenPayroll({ providerId });
      onOpenPayrollModal?.();
    } catch (error) {
      toast.error('Unable to reopen payroll');
    }
  };

  return (
    <StyledTable>
      <Thead>
        <Tr>
          <Th>Payday</Th>
          <Th>Pay Period</Th>
          <Th>Type</Th>
          <Th>Status</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {payrolls.map((payroll) => (
          <Tr key={payroll.providerId}>
            <Td>{formatDate(payroll.payday)}</Td>
            <Td>{`${formatDate(payroll.periodStart)} - ${formatDate(payroll.periodEnd)}`}</Td>
            <Td>{formatName(payroll.type)}</Td>
            <Td>
              {payroll.status === PayrollStatus.Draft && (
                <DraftStatus>{payroll.status}</DraftStatus>
              )}
              {payroll.status === PayrollStatus.Pending && (
                <PendingStatus>{payroll.status}</PendingStatus>
              )}
              {payroll.status === PayrollStatus.Processing && (
                <ProcessingStatus>{payroll.status}</ProcessingStatus>
              )}
              {payroll.status === PayrollStatus.Failed && (
                <FailedStatus>{payroll.status}</FailedStatus>
              )}
              {payroll.status === PayrollStatus.PartiallyPaid && (
                <PartiallyPaidStatus>{formatName(payroll.status)}</PartiallyPaidStatus>
              )}
              {payroll.status === PayrollStatus.Paid && <PaidStatus>{payroll.status}</PaidStatus>}
            </Td>
            <Td>
              {payroll.status === PayrollStatus.Draft && (
                <Button onClick={onOpenPayrollModal}>Open Payroll</Button>
              )}
              {payroll.status === PayrollStatus.Pending && (
                <Button onClick={() => handleReOpenPayroll(payroll.providerId)}>
                  Re-Open Payroll
                </Button>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </StyledTable>
  );
};
