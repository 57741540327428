import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment, { Moment } from 'moment';
import { Controller, Control, FieldPath, FieldValues } from 'react-hook-form';

const generateYears = (
  relativeTo: moment.Moment,
  count: number,
  restriction?: 'past' | 'future'
) => {
  if (restriction === 'past') {
    return Array(count)
      .fill(0)
      .map((_val, i) => relativeTo.clone().subtract(i, 'years').year());
  } else if (restriction === 'future') {
    return Array(count)
      .fill(0)
      .map((_val, i) => relativeTo.clone().add(i, 'years').year());
  } else {
    const half = Math.floor(count / 2);
    return Array(count)
      .fill(0)
      .map((_val, i) =>
        relativeTo
          .clone()
          .subtract(half - i, 'years')
          .year()
      );
  }
};

type DateSelectorProps<TFieldValues extends FieldValues> = {
  placeholder: string;
  onDateChange: (date: Moment | null) => void;
  control?: Control<TFieldValues>;
  name?: FieldPath<TFieldValues>;
  defaultValue?: string;
  openDirection?: 'up' | 'down';
  dateRestriction?: 'past' | 'future';
};

export const DateSelector = <TFieldValues extends FieldValues>({
  placeholder,
  onDateChange,
  control,
  name,
  defaultValue,
  openDirection = 'down',
  dateRestriction,
}: DateSelectorProps<TFieldValues>) => {
  const [focused, setFocused] = useState(false);
  const [date, setDate] = useState<Moment | null>(defaultValue ? moment(defaultValue) : null);

  const years = generateYears(moment(), 200, dateRestriction);

  const onYearChange = (year: string) => {
    if (date) {
      const newDate = date.clone().year(Number(year));
      setDate(newDate);
    }
  };

  const isDateRestricted = (selectedDate: Moment) => {
    if (dateRestriction === 'past') {
      return selectedDate.isAfter(moment());
    }
    if (dateRestriction === 'future') {
      return selectedDate.isBefore(moment());
    }
    return false;
  };

  const renderPicker = () => (
    <SingleDatePicker
      id="date_selector"
      date={date}
      onDateChange={(selectedDate) => {
        setDate(selectedDate);
        onDateChange(selectedDate);
      }}
      focused={focused}
      onFocusChange={({ focused: isInputFocused }) => setFocused(isInputFocused)}
      numberOfMonths={1}
      showDefaultInputIcon
      inputIconPosition="after"
      displayFormat="MM/DD/YYYY"
      hideKeyboardShortcutsPanel
      isOutsideRange={isDateRestricted}
      placeholder={placeholder}
      openDirection={openDirection}
      renderMonthElement={({ month, onYearSelect }) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>{month.format('MMMM')}</div>
          <select
            value={month.year()}
            onChange={(e) => {
              onYearSelect(month, e.target.value);
              onYearChange(e.target.value);
            }}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      )}
    />
  );

  return control && name ? (
    <Controller control={control} name={name} render={({ field }) => renderPicker()} />
  ) : (
    renderPicker()
  );
};
