import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddressFormData } from './types';
import { addressSchema } from './validators';
import { FormInput, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { updateProfile } from 'lib/updateProfile';
import { Employee } from 'features/user/types';
import { toast } from 'react-toastify';
import { AddressFormContainer, Form, ButtonContainer } from './EmployeeAddressForm.styles';

type EmployeeAddressFormProps = {
  employee: Employee;
  handleFormSubmit: () => void;
};

export const EmployeeAddressForm = ({ employee, handleFormSubmit }: EmployeeAddressFormProps) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressFormData>({
    resolver: yupResolver(addressSchema),
    defaultValues: {
      line1: employee.providerAttributes.address.line1,
      line2: employee.providerAttributes.address.line2 || '',
      city: employee.providerAttributes.address.city,
      state: employee.providerAttributes.address.state,
      postalCode: employee.providerAttributes.address.postalCode,
    },
  });

  const onSubmit: SubmitHandler<AddressFormData> = async (data: AddressFormData) => {
    if (Object.keys(errors).length === 0) {
      const profileUpdateData = {
        address: {
          line1: data.line1,
          line2: data.line2,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          country: 'US',
        },
      };

      const response = await updateProfile(profileUpdateData);
      if (response) {
        toast.success('The address has been successfully updated.');
        handleFormSubmit();
      }
    }
  };

  const isButtonDisabled = (): boolean =>
    !watch('line1') || !watch('city') || !watch('state') || !watch('postalCode');

  return (
    <AddressFormContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup label="Address">
          <FormInput
            id="line1"
            placeholder="Street Address Line 1"
            type="text"
            required
            {...register('line1')}
          />
          <FormInput
            id="line2"
            placeholder="Street Address Line 2"
            type="text"
            {...register('line2')}
          />
          <FormInput id="city" placeholder="City" type="text" required {...register('city')} />
          <FormInput id="state" placeholder="State" type="text" required {...register('state')} />
          <FormInput
            id="postalCode"
            placeholder="Postal Code"
            type="text"
            required
            {...register('postalCode')}
          />
        </FormGroup>
        <ButtonContainer>
          <ActionButton
            size="medium"
            title="Save"
            hidden={false}
            disabled={isButtonDisabled()}
            shape="fullWidth"
            onClick={handleSubmit(onSubmit)}
          />
        </ButtonContainer>
      </Form>
    </AddressFormContainer>
  );
};
