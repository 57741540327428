import {
  NotificationContainer,
  Container,
  Heading,
  Message,
  ButtonContainer,
} from '../notification.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';

export const AddEmployeeNotification = () => {
  const navigate = useNavigate();
  return (
    <NotificationContainer>
      <Container>
        <Heading>Add your first employee</Heading>
        <Message>Let&apos;s onboard your employees so that you can start running payrolls!</Message>
      </Container>
      <ButtonContainer>
        <ActionButton
          onClick={() => navigate('/people/add')}
          size="medium"
          title="Add Employees"
          hidden={false}
        />
      </ButtonContainer>
    </NotificationContainer>
  );
};
