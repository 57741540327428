import { procareApi } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { APIError, sendErrorMessage } from 'lib/sendErrorMessage';

export enum EarningType {
  Bonus = 'bonus',
  Commission = 'commission',
  Hourly = 'hourly',
  Overtime = 'overtime',
  PTO = 'pto',
  Sick = 'sick',
  Salaried = 'salaried',
}

export type PayrollInput = {
  providerId: string;
};

export type PayrollEarning = {
  amount: number;
  description: string;
  earningCode: string;
  earningRate: number;
  hours: number;
  type: EarningType;
  workplaceIdentifier: string;
};

export type PayrollReimbursement = {
  amount: number;
  code: string;
  description: string;
};

export type PayrollItem = {
  payrollItemId: string;
  netPay: number;
  paymentMethod: string;
  payrollProviderId: string;
  providerId: string;
  status: string;
  employee: {
    id: string;
    name: string;
    onboarded: boolean;
    compensation: {
      amount: number;
      type: string;
    };
  };
  earnings: PayrollEarning[];
  reimbursements: PayrollReimbursement[];
};

type PayrollTotals = {
  cashRequirement: number;
  companyBenefits: number;
  companyTaxes: number;
  contractorGross: number;
  contractorNet: number;
  contractorReimbursements: number;
  employeeBenefits: number;
  employeeGross: number;
  employeeNet: number;
  employeeReimbursements: number;
  employeeTaxes: number;
  liability: number;
  postTaxDeductions: number;
};

export type PayrollDetails = {
  approvalDeadline: string;
  approvedAt: string;
  fundingPaymentMethod: string;
  isVoid: boolean;
  managed: boolean;
  offCycleOptions: string;
  payFrequency: string;
  payday: string;
  periodEnd: string;
  periodStart: string;
  processingPeriod: string;
  providerId: string;
  status: string;
  type: string;
  totals: PayrollTotals;
  items: PayrollItem[];
};

type ProviderPayrollResponse = {
  data: {
    providerPayroll: PayrollDetails;
  };
};

export const fetchProviderPayroll = (providerId: string): Promise<PayrollDetails | void> => {
  const query = `
    query ProviderPayroll($payrollInput: PayrollInput!) {
      providerPayroll(payrollInput: $payrollInput) {
        approvalDeadline
        approvedAt
        fundingPaymentMethod
        isVoid
        managed
        offCycleOptions
        payFrequency
        payday
        periodEnd
        periodStart
        processingPeriod
        providerId
        status
        type
        totals {
          cashRequirement
          companyBenefits
          companyTaxes
          contractorGross
          contractorNet
          contractorReimbursements
          employeeBenefits
          employeeGross
          employeeNet
          employeeReimbursements
          employeeTaxes
          liability
          postTaxDeductions
        }
        items {
          payrollItemId
          netPay
          paymentMethod
          payrollProviderId
          providerId
          status
          employee {
            id
            name
            onboarded
            compensation {
              amount
              type
            }
          }
          earnings {
            amount
            description
            earningCode
            earningRate
            hours
            type
            workplaceIdentifier
          }
          reimbursements {
            amount
            code
            description
          }
        }
      }
    }
  `;

  return procareApi
    .post<unknown, AxiosResponse<ProviderPayrollResponse>>('graphql', {
      query,
      variables: { payrollInput: { providerId } },
    })
    .then((response: AxiosResponse<ProviderPayrollResponse>) => {
      return response.data.data.providerPayroll;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
      throw error;
    });
};
