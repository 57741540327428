import { useAppSelector } from 'hooks/redux';
import { ContentContainer, PageContainer } from 'components/SharedComponents/Containers.styles';
import { AddEmployeeNotification } from 'components/DashboardNotifications/AddEmployeeNotification/addEmployeeNotification';
import { NavCardList } from 'components/NavCardsList/NavCardList';
import { adminNavData, employerNavData } from '@src/constants/navData';
import { Roles } from 'features/user/types';

export const CompanyDashboard = () => {
  const { currentUser } = useAppSelector((state) => state.user);

  const selectedNavData =
    currentUser?.role.name === Roles.Employer ? employerNavData : adminNavData;

  return (
    <PageContainer>
      <ContentContainer noHeight={true}>
        <AddEmployeeNotification />
      </ContentContainer>
      <ContentContainer noHeight={true}>
        <NavCardList navData={selectedNavData} />
      </ContentContainer>
    </PageContainer>
  );
};
