import { Fragment } from 'react';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentCompany } from 'features/company/slice';
import { Company, Workplace } from 'features/company/types';
import {
  CreateWorkplaceContainer,
  WorkplaceHeadingContainer,
  WorkplaceHeading,
  WorkplaceContainer,
  WorkplaceSubHeadingContainer,
  WorkplaceSubHeading,
  WorkplaceMessage,
  WorkplaceCount,
  WorkplaceList,
  WorkplaceListItem,
  ButtonContainer,
  Address,
} from './CreateWorkplace.styles';
import { WorkplaceForm } from './WorkplaceForm/WorkplaceForm';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { getFullAddress } from 'lib/utils/getFullAddress';

export const CreateWorkplace = () => {
  const navigate = useNavigate();
  const company: Company | null = useAppSelector(selectCurrentCompany);

  const renderWorkplaceList = () => {
    if (!company || !company.workplaces.length) return null;

    return (
      <>
        <WorkplaceCount>
          <strong>({company.workplaces.length})</strong> Workplaces have already been added for this
          company
        </WorkplaceCount>
        <WorkplaceList>
          {company.workplaces.map((workplace: Workplace) => (
            <WorkplaceListItem key={workplace.id}>
              {workplace.name}
              <br />
              {getFullAddress(workplace.providerAttributes.address).map((line, index) => (
                <Address key={index}>{line}</Address>
              ))}
            </WorkplaceListItem>
          ))}
        </WorkplaceList>
      </>
    );
  };

  return (
    <CreateWorkplaceContainer>
      <WorkplaceHeadingContainer>
        <WorkplaceHeading>Let&apos;s get started!</WorkplaceHeading>
      </WorkplaceHeadingContainer>
      <WorkplaceContainer>
        {company && company.workplaces.length > 0 ? (
          <>
            {renderWorkplaceList()}
            <ButtonContainer>
              <ActionButton
                onClick={() => navigate('/pay-schedule')}
                size="medium"
                title="Continue"
                hidden={false}
              />
            </ButtonContainer>
          </>
        ) : (
          <>
            <WorkplaceSubHeadingContainer>
              <WorkplaceSubHeading>Where is your Workplace?</WorkplaceSubHeading>
              <WorkplaceMessage>
                In order to accurately calculate tax for employees, we need to keep track of your
                company&apos;s physical work locations.
              </WorkplaceMessage>
            </WorkplaceSubHeadingContainer>
            <WorkplaceForm />
          </>
        )}
      </WorkplaceContainer>
    </CreateWorkplaceContainer>
  );
};
