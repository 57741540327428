import { useState, useEffect } from 'react';
import { ContentContainer, Section } from 'components/SharedComponents/Containers.styles';
import { ActionButton } from 'components/ActionButton/actionButton';
import Tooltip from 'components/Tooltip/tooltip';
import ReactModal from 'react-modal';
import { RunPayroll } from 'components/RunPayroll/runPayroll';
import { PayrollsTable } from 'components/Table/PayrollsTable/PayrollsTable';
import { fetchProviderEmployeesList } from 'lib/fetchProviderEmployeesList';
import { SectionHeading, Container, RunPayrollContainer, RadioContainer } from './Dashboard.styles';
import { Timeline } from 'components/Timeline/Timeline';
import { OffCyclePayrollForm } from 'components/OffCyclyePayrollForm/OffCyclePayrollForm';
import { RadioInput } from 'components/form/RadioInput/RadioInput';
import { useNavigate } from 'react-router-dom';
import { fetchCompanyNextPayday, Payday } from 'lib/fetchCompanyNextPayday';
import { fetchCompanyPayrolls, PayrollStatus, PayrollTypes } from 'lib/fetchCompanyPayrolls';

const PayrollTypesEnum = {
  Regular: 'Regular Payroll',
  OffCycle: 'Off-Cycle Payroll',
};

export const Dashboard = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [hasEmployees, setHasEmployees] = useState(true);
  const [selectedPayrollType, setSelectedPayrollType] = useState(PayrollTypesEnum.Regular);
  const [payday, setPayday] = useState<Payday | null>(null);
  const [PayrollProviderId, setPayrollProviderId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const employeesList = await fetchProviderEmployeesList();
      setHasEmployees(employeesList.employees.length > 0);

      const nextPayday = await fetchCompanyNextPayday();
      setPayday(nextPayday);

      if (nextPayday) {
        const { payrolls } = await fetchCompanyPayrolls(undefined, {
          status: PayrollStatus.Draft,
          type: PayrollTypes.Regular,
          paydayBefore: nextPayday.payday,
          paydayAfter: nextPayday.payday,
        });

        if (payrolls.length > 0) {
          setPayrollProviderId(payrolls[0].providerId);
        } else {
          setPayrollProviderId(null);
        }
      }

      setLoading(false);
    };

    fetchData();
  }, [refresh]);

  const closeModal = () => {
    setModalIsOpen(false);
    setRefresh(!refresh);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const renderActionButton = () => {
    if (loading) {
      return null;
    }

    const button = (
      <ActionButton
        onClick={() =>
          navigate('/payroll/run-payroll/hours', {
            state: { PayrollProviderId },
          })
        }
        size="medium"
        title={PayrollProviderId ? 'Continue Payroll' : 'Run Payroll'}
        hidden={false}
        disabled={!hasEmployees}
      />
    );

    if (!hasEmployees) {
      return (
        <Tooltip
          content="You must add at least 1 employee before you can Run Payroll."
          direction="left"
        >
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  const radioOptions = [
    { value: PayrollTypesEnum.Regular, label: 'Regular Payroll' },
    { value: PayrollTypesEnum.OffCycle, label: 'Off-Cycle Payroll' },
  ];

  return (
    <ContentContainer>
      <Container>
        <Timeline payday={payday} />
        <Section halfWidth>
          <SectionHeading>
            {PayrollProviderId ? 'Pick up where you left off' : 'Setup your next Payroll'}
          </SectionHeading>
          <RadioContainer>
            <RadioInput
              name="payrollType"
              options={radioOptions}
              selectedValue={selectedPayrollType}
              onChange={(value) => setSelectedPayrollType(value)}
            />
          </RadioContainer>
          <RunPayrollContainer>
            {selectedPayrollType === PayrollTypesEnum.Regular && renderActionButton()}
            {selectedPayrollType === PayrollTypesEnum.OffCycle && <OffCyclePayrollForm />}
          </RunPayrollContainer>
        </Section>
      </Container>
      <Section>
        <SectionHeading>Recent Payrolls</SectionHeading>
        <PayrollsTable
          refresh={refresh}
          pageSize={3}
          disablePagination={true}
          onOpenPayrollModal={openModal}
        />
      </Section>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 11,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            width: '70vw',
          },
        }}
      >
        <RunPayroll closeModal={closeModal} />
      </ReactModal>
    </ContentContainer>
  );
};
