import { loginSchema, signupSchema } from 'components/AuthForm/validator';
import { type Asserts } from 'yup';
import { Company } from 'features/company/types';

export enum Roles {
  PayrollAdmin = 'payroll_admin',
  Employer = 'employer',
  Employee = 'employee',
}

export type CurrentRole = {
  id: string;
  name: Roles;
};

export type User = {
  id: string;
  email: string;
  name: string;
  providerIdentifier: string;
  currentRole: CurrentRole;
  permissions?: Permissions[];
  employees?: Employee[];
  companies?: Company[];
  signatory?: Signatory;
};

export type Permissions = {
  allowedFields: string[];
  id: string;
  operation: string;
  resource: string;
};

export type Role = {
  id: string;
  name: Roles;
  permissions: Permissions[];
};

export type Address = {
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postalCode: string;
  state: string;
};

export type Onboard = {
  blockingSteps: string[];
  remainingSteps: string[];
  status: string;
};

export enum PaymentPreferences {
  DirectDeposit = 'direct_deposit',
  Manual = 'manual',
}

export type ProviderEmployee = {
  address: Address;
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  onboard: Onboard;
  paymentMethodPreference?: PaymentPreferences;
  providerIdentifier: string;
  ssnLastFour?: string;
  startDate: string;
};

export type ProviderWorkplace = {
  active: boolean;
  address?: Address;
  providerIdentifier: string;
};

export type Compensation = {
  amount: string;
  type: string;
};

export type Workplace = {
  company: Company;
  id: string;
  name: string;
  platformIdentifier?: string;
  providerAttributes: ProviderWorkplace;
  providerIdentifier?: string;
};

export type Employee = {
  company: Company;
  compensation: Compensation;
  id: string;
  name: string;
  platformIdentifier?: string;
  providerAttributes: ProviderEmployee;
  providerIdentifier: string;
  workplaces: Workplace[];
  roles?: Role[];
};

export type EmployeeDocument = {
  id: string;
  filedOn: string;
  label: string;
};

export type Signatory = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  providerIdentifier: string;
};

export type School = {
  id: string;
  name: string;
  time_zone: string | null;
};

export type CurrentUser = {
  currentUser: User;
};

export type UserResponse = {
  data: CurrentUser;
};

export type FormData = Asserts<typeof loginSchema>;

export type SignupFormData = Asserts<typeof signupSchema>;

export type SignupData = {
  name: string;
  email: string;
  password: string;
  company_id: string | null;
};

export type UserData = {
  user: SignupData;
};

export type OnboardParams = {
  password: string;
  confirmToken: string;
};

export type Auth = {
  access_token: string;
  refresh_token: string;
};

export type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
  loading: boolean;
};

export type LoginResponse = {
  auth: Auth;
};

export type ErrorResponse = {
  errors: string[];
  message: string;
};

export type RefreshToken = {
  refresh_token?: string;
};

export type EmployeeContext = {
  company: Company;
  compensation: Compensation;
  id: string;
  name: string;
  platformIdentifier?: string;
  providerAttributes?: ProviderEmployee;
  providerIdentifier: string;
  workplaces: Workplace[];
  roles?: Role[];
};

export type RoleContext = {
  id: string;
  name: Roles;
  permissions: Permissions[];
};

export type UserContext = {
  email: string;
  id: string;
  name: string;
  companies?: Company[];
};

export type CurrentUserContext = {
  employee: EmployeeContext;
  role: RoleContext;
  signatory: Signatory;
  user: UserContext;
};

export type CurrentUserContextResponse = {
  data: {
    currentUserContext: CurrentUserContext;
  };
};

export type UserState = {
  currentUser: CurrentUserContext | null;
  loading: boolean;
  employeeOnboarded?: boolean;
};
