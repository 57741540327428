import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { payScheduleSchema } from './validator';
import { PayScheduleFormData, PayFrequencyType } from './types';
import { Form, PayScheduleFormContainer, ButtonContainer, Instruction } from './paySchedule.styles';
import { FormSelect, FormGroup } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import { DateInput } from 'components/form/DateInput/dateInput';
import moment from 'moment';
import { createPaySchedule } from 'lib/createPaySchedule';
import { setCompanyStartDate } from 'lib/setCompanyStartDate';
import { toast } from 'react-toastify';

const defaultFormValues = {
  payFrequency: Object.values(PayFrequencyType)[0],
};

type PayScheduleFormProps = {
  onboarding?: boolean;
};

export const PayScheduleForm = ({ onboarding }: PayScheduleFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PayScheduleFormData>({
    resolver: yupResolver(payScheduleSchema),
    defaultValues: defaultFormValues,
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<PayScheduleFormData> = async (data: PayScheduleFormData) => {
    if (Object.keys(errors).length === 0) {
      const payScheduleData: PayScheduleFormData = {
        payFrequency: data.payFrequency,
        firstPayday: moment(data.firstPayday).format('YYYY-MM-DD'),
        firstPeriodEnd: moment(data.firstPeriodEnd).format('YYYY-MM-DD'),
      };

      const response = await createPaySchedule(payScheduleData);

      if (response && onboarding) {
        // When onboarding, perform the second mutation to set Start Date
        const startDateFormatted = moment(data.startDate).format('YYYY-MM-DD');
        await setCompanyStartDate(startDateFormatted);
      }

      if (response) {
        toast.success('Pay Schedule has been successfully added.');
        navigate('/company-onboarding');
      }
    }
  };

  const isButtonDisabled = (): boolean => {
    const startDateValue = watch('startDate');
    const firstPaydayValue = watch('firstPayday');

    const isFieldsEmpty =
      !watch('payFrequency') || !watch('firstPayday') || !watch('firstPeriodEnd');

    if (isFieldsEmpty) {
      return true;
    }

    if (onboarding && startDateValue !== firstPaydayValue) {
      return true;
    }

    return false;
  };

  const selectedPayFrequency = watch('payFrequency');

  const payFrequencyOptions = Object.values(PayFrequencyType).map((type) => ({
    value: type,
    label: type,
  }));

  const defaultPayFrequencyValue = payFrequencyOptions.length ? payFrequencyOptions[0].value : '';

  return (
    <PayScheduleFormContainer>
      <Form>
        <FormGroup label="Pay Frequency">
          <FormSelect
            id="payFrequency"
            required
            options={payFrequencyOptions}
            defaultValue={defaultPayFrequencyValue}
            {...register('payFrequency')}
          />
        </FormGroup>
        <FormGroup label="Period Details">
          <DateInput control={control} name="firstPeriodEnd" placeholder="First Period End" />
          {selectedPayFrequency === PayFrequencyType.SEMIMONTHLY && (
            <Instruction>
              First Pay Day must be either the 15th or the last day of the month when using a Semi
              Monthly pay frequency.
            </Instruction>
          )}
          <DateInput
            control={control}
            name="firstPayday"
            placeholder="First Pay Day"
            dateRestriction="future"
          />
        </FormGroup>
        {onboarding && (
          <FormGroup label="Start Date">
            <Instruction>
              This date should be a future date and should be the same as your first pay day. This
              date is the first date Payroll will officially be run.
            </Instruction>
            <DateInput
              control={control}
              name="startDate"
              placeholder="Start Date"
              dateRestriction="future"
            />
          </FormGroup>
        )}
      </Form>
      <ButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="large"
          title="Continue"
          hidden={false}
          disabled={isButtonDisabled()}
        />
      </ButtonContainer>
    </PayScheduleFormContainer>
  );
};
