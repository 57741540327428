import styled from 'styled-components';
import { Modal } from 'components/Modal/Modal';
import { IconButton } from 'components/IconButton/IconButton';

export const CloseButton = styled(IconButton)`
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;
`;

export const EditFormContainer = styled.div``;

export const FormHeading = styled.h3`
  font-size: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black};
  margin: 10px 20px 0;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray6};
`;

export const SsnModal = styled(Modal)`
  iframe {
    height: 85vh !important;
    width: 70vw !important;
    max-height: 800px;
    border-radius: 16px;
  }
`;

export const StatusSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
`;
