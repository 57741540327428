import { Label, Input, RadioInputContainer } from './RadioInput.styles';

type RadioInputProps = {
  name: string;
  options: { value: string; label: string }[];
  selectedValue: string;
  onChange: (value: string) => void;
};

export const RadioInput = ({ name, options, selectedValue, onChange }: RadioInputProps) => {
  return (
    <RadioInputContainer>
      {options.map((option) => (
        <Label key={option.value}>
          <Input
            type="radio"
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => onChange(option.value)}
          />
          {option.label}
        </Label>
      ))}
    </RadioInputContainer>
  );
};
