import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Step } from './Step';
import { ActionButton } from 'components/ActionButton/actionButton';
import {
  StepIndicatorContainer,
  StepContentContainer,
  StepConnector,
  NavigationContainer,
  StepsContainer,
} from './StepsComponent.styles';

export type StepDetail = {
  label: string;
  content: React.ReactNode;
};

type StepsComponentProps = {
  initialActiveStep: number;
  steps: StepDetail[];
  onStepChange?: (stepIndex: number) => void;
  onSubmit?: () => void;
};

export const StepsComponent = ({
  initialActiveStep,
  steps,
  onStepChange,
  onSubmit,
}: StepsComponentProps) => {
  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const navigate = useNavigate();
  const { stepLabel } = useParams();

  useEffect(() => {
    const stepIndex = steps.findIndex(
      (step) => step.label.toLowerCase().replace(/\s+/g, '-') === stepLabel
    );
    if (stepIndex !== -1) {
      setActiveStep(stepIndex);
    }
  }, [stepLabel, steps]);

  const navigateToStep = (index: number) => {
    const stepPath = steps[index].label.toLowerCase().replace(/\s+/g, '-');
    navigate(`/payroll/run-payroll/${stepPath}`);
    setActiveStep(index);
    if (onStepChange) {
      onStepChange(index);
    }
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      const nextStep = steps[activeStep + 1].label.toLowerCase().replace(/\s+/g, '-');
      navigate(`/payroll/run-payroll/${nextStep}`);
      setActiveStep(activeStep + 1);
      if (onStepChange) {
        onStepChange(activeStep + 1);
      }
    } else {
      if (onSubmit) {
        onSubmit();
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      const prevStep = steps[activeStep - 1].label.toLowerCase().replace(/\s+/g, '-');
      navigate(`/payroll/run-payroll/${prevStep}`);
      setActiveStep(activeStep - 1);
      if (onStepChange) {
        onStepChange(activeStep - 1);
      }
    }
  };

  const isLastStep = activeStep === steps.length - 1;

  return (
    <StepsContainer>
      <StepIndicatorContainer>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Step
              stepNumber={index + 1}
              label={step.label}
              isActive={index === activeStep}
              isCompleted={index < activeStep}
              onClick={() => navigateToStep(index)}
            />
            {index < steps.length - 1 && <StepConnector />}
          </React.Fragment>
        ))}
      </StepIndicatorContainer>
      <StepContentContainer>{steps[activeStep].content}</StepContentContainer>
      <NavigationContainer>
        {activeStep > 0 && (
          <ActionButton onClick={handleBack} variant="outline" size="medium" title="Back" />
        )}
        <ActionButton
          onClick={handleNext}
          size="medium"
          title={isLastStep ? 'Submit' : 'Continue'}
        />
      </NavigationContainer>
    </StepsContainer>
  );
};
