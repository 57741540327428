import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { WorkplaceInput } from 'components/CreateWorkplace/WorkplaceForm/WorkplaceForm';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type Workplace = {
  id: string;
  providerIdentifier: string;
  name: string;
};

type CreateWorkplacePayload = {
  workplace: Workplace;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const createWorkplace = (workplaceParams: WorkplaceInput): Promise<Workplace | void> => {
  const mutation = `
      mutation($workplaceParams: WorkplaceInput!) {
        createWorkplace(workplaceParams: $workplaceParams) {
          workplace {
            id
            providerIdentifier
            name
          }
          error {
            message
            type
            inputErrors {
              field
              fieldPath
              message
            }
          }
        }
      }
    `;

  const variables = {
    workplaceParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { createWorkplace: CreateWorkplacePayload } }>>(
      'graphql',
      { query: mutation, variables }
    )
    .then((response: AxiosResponse<{ data: { createWorkplace: CreateWorkplacePayload } }>) => {
      if (response.data.data.createWorkplace.error) {
        sendErrorMessage(response.data.data.createWorkplace.error);
      }
      return response.data.data.createWorkplace.workplace;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
