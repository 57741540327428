import styled, { css } from 'styled-components';

export const Td = styled.td`
  padding: 20px;
  border-top: 1px solid #ddd;
  text-transform: capitalize;
`;

const Status = css`
  text-transform: capitalize;
  border-radius: 15px;
  padding: 8px 16px;
`;

export const DraftStatus = styled.span`
  background-color: ${(props) => props.theme.colors.draft};
  ${Status}
`;

export const PendingStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;

export const ProcessingStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;

export const FailedStatus = styled.span`
  background-color: ${(props) => props.theme.colors.blockingStatus};
  ${Status}
`;

export const PartiallyPaidStatus = styled.span`
  background-color: #ffffcc;
  ${Status}
`;

export const PaidStatus = styled.span`
  background-color: ${(props) => props.theme.colors.completedStatus};
  ${Status}
`;
