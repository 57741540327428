import styled from 'styled-components';
import { hexToRgba } from 'styles/mixins';

export const CreateWorkplaceContainer = styled.div`
  min-width: 800px;
  padding: 20px 0;
`;

export const WorkplaceHeadingContainer = styled.div`
  padding: 16px 0 0;
  border-bottom: 2px solid ${(props) => hexToRgba(props.theme.colors.offWhite, 0.75)};
`;

export const WorkplaceHeading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.heading};
  font-weight: 500;
  color: ${(props) => props.theme.colors.black0};
`;

export const WorkplaceContainer = styled.div`
  padding: 16px 0;
`;

export const WorkplaceSubHeadingContainer = styled.div``;

export const WorkplaceSubHeading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  font-weight: 500;
  margin: 10px 0 15px;
`;

export const WorkplaceMessage = styled.div`
  font-size: ${(props) => props.theme.fontSizes.normal};
`;

export const WorkplaceCount = styled.div`
  margin-top: 10px;
`;

export const WorkplaceList = styled.ul`
  border-bottom: 2px solid ${(props) => hexToRgba(props.theme.colors.offWhite, 0.75)};
  padding: 10px 0 15px;
`;

export const WorkplaceListItem = styled.li`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.default};
  font-weight: 500;
  margin: 10px 20px;
`;

export const WorkplaceFormHeading = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.fontSizes.subHeading};
  font-weight: 400;
  margin: 20px 0 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px;
`;

export const Address = styled.span`
  display: block;
`;
