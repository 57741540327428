import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Table } from './Table';
import { fetchProviderEmployeesList, Employee } from 'lib/fetchProviderEmployeesList';
import { CurrentUserContext } from 'features/user/types';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';

type UserState = {
  user: {
    currentUser: CurrentUserContext | null;
  };
};

type PageInfo = {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
};

export const EmployeesTable = () => {
  const { currentUser } = useAppSelector((state: UserState) => state.user);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);

  const loadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo && pageInfo.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      loadMore();
    }
  };

  useEffect(() => {
    fetchProviderEmployeesList(endCursor).then(
      ({ employees: newEmployees, pageInfo: newPageInfo }) => {
        setEmployees(newEmployees);
        setPageInfo(newPageInfo);
        setLoading(false);
      }
    );
  }, [currentUser, endCursor]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      {pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
        <Pagination
          onPrev={onPrev}
          onNext={onNext}
          disablePrev={!pageInfo.hasPreviousPage}
          disableNext={!pageInfo.hasNextPage}
        />
      )}
      <Table employees={employees} />
    </>
  );
};
