import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '@src/assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '@src/assets/icons/arrow-up.svg';

export const FormSelectContainer = styled.div`
  min-width: 360px;
  margin-bottom: 16px;
`;

export const FormLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.normal};
  text-transform: uppercase;
`;

export const StyledArrowDownIcon = styled(ArrowDownIcon)`
  position: absolute;
  width: 20px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: ${(props) => props.theme.colors.gray7};
  pointer-events: none;
`;

export const ArrowIcon = styled(({ isOpen, ...props }) =>
  isOpen ? <ArrowUpIcon {...props} /> : <ArrowDownIcon {...props} />
)<{ isOpen: boolean }>`
  position: absolute;
  width: 20px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: ${(props) => props.theme.colors.gray7};
  pointer-events: none;
`;

export const Select = styled.select`
  position: relative;
  appearance: none;
  margin: 8px 0;
  width: 100%;
  min-width: 140px;
  padding: 3px 10px 0;
  height: 40px;
  text-transform: capitalize;
  border: 1px solid ${(props) => props.theme.colors.gray0};
  border-radius: 6px;
  outline: none;

  font-size: ${(props) => props.theme.fontSizes.normal};
  font-family: ${(props) => props.theme.fontFamily.default};

  &:focus {
    border-color: ${(props) => props.theme.colors.blue2} !important;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.disabled};
    color: ${(props) => props.theme.colors.black2};
  }
`;

export const SelectWrapper = styled.div``;
