import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeFormData } from '../types';
import { updateEmployeeSchema } from '../validator';
import { FormInput, FormGroup, FormSelect } from 'components/form';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useAppSelector } from 'hooks/redux';
import { selectCurrentCompany } from 'features/company/slice';
import { Company } from 'features/company/types';
import { updateEmployee, UpdateEmployeeInput } from 'lib/updateEmployee';
import { Employee } from 'features/user/types';
import { DateInput } from 'components/form/DateInput/dateInput';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  UpdateEmployeeFormContainer,
  UpdateForm,
  UpdateButtonContainer,
  FormInputGroup,
} from './updateEmployeeForm.styles';

type UpdateEmployeeFormProps = {
  id: string;
  employee: Employee;
  handleFormSubmit: (updatedEmployee: Employee) => void;
};

export const UpdateEmployeeForm = ({ employee, handleFormSubmit }: UpdateEmployeeFormProps) => {
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeFormData>({
    resolver: yupResolver(updateEmployeeSchema),
    defaultValues: {
      firstName: employee.providerAttributes.firstName,
      lastName: employee.providerAttributes.lastName,
      line1: employee.providerAttributes.address.line1,
      city: employee.providerAttributes.address.city,
      state: employee.providerAttributes.address.state,
      postalCode: employee.providerAttributes.address.postalCode,
      dateOfBirth: moment(employee.providerAttributes.dateOfBirth).format('YYYY-MM-DD'),
      workplaceId: employee.workplaces[0].id,
    },
  });

  const company: Company | null = useAppSelector(selectCurrentCompany);

  const onSubmit: SubmitHandler<EmployeeFormData> = async (data: EmployeeFormData) => {
    if (Object.keys(errors).length === 0) {
      const updatedEmployeeData: UpdateEmployeeInput = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: employee.providerAttributes.email,
        dateOfBirth: moment(data.dateOfBirth).format('YYYY-MM-DD'),
        startDate: employee.providerAttributes.startDate,
        compensation: employee.compensation,
        address: {
          line1: data.line1,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          country: 'US',
        },
        workplaceId: data.workplaceId,
        providerIdentifier: employee.providerIdentifier,
      };

      const response = await updateEmployee(updatedEmployeeData);
      if (response) {
        toast.success('The employee has been successfully updated.');
        handleFormSubmit(response);
      }
    }
  };

  const isButtonDisabled = (): boolean =>
    !watch('firstName') ||
    !watch('lastName') ||
    !watch('line1') ||
    !watch('city') ||
    !watch('state') ||
    !watch('postalCode') ||
    !watch('dateOfBirth') ||
    !watch('workplaceId');

  return (
    <UpdateEmployeeFormContainer>
      <UpdateForm>
        <FormGroup label="Personal Information">
          <FormInputGroup>
            <FormInput
              id="firstName"
              placeholder="First Name"
              column={2}
              type="text"
              required
              {...register('firstName')}
            />

            <FormInput
              id="lastName"
              placeholder="Last Name"
              column={2}
              type="text"
              required
              {...register('lastName')}
            />
          </FormInputGroup>

          <DateInput
            control={control}
            name="dateOfBirth"
            placeholder="Date of Birth"
            defaultValue={moment(employee.providerAttributes.dateOfBirth).format('YYYY-MM-DD')}
          />
        </FormGroup>

        <FormGroup label="Address">
          <FormInput
            id="line1"
            placeholder="Street Address"
            type="text"
            required
            {...register('line1')}
          />

          <FormInput id="city" placeholder="City" type="text" required {...register('city')} />

          <FormInput id="state" placeholder="State" type="text" required {...register('state')} />

          <FormInput
            id="postalCode"
            placeholder="Postal Code"
            type="text"
            required
            {...register('postalCode')}
          />
        </FormGroup>

        <FormGroup label="Workplace">
          <FormSelect
            id="workplaceId"
            required
            options={
              company?.workplaces.map((workplace) => ({
                value: workplace.id,
                label: workplace.name,
              })) || []
            }
            {...register('workplaceId')}
          />
        </FormGroup>
      </UpdateForm>
      <UpdateButtonContainer>
        <ActionButton
          onClick={handleSubmit(onSubmit)}
          size="medium"
          title="Save"
          hidden={false}
          disabled={isButtonDisabled()}
          shape="fullWidth"
        />
      </UpdateButtonContainer>
    </UpdateEmployeeFormContainer>
  );
};
