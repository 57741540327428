import { useState } from 'react';
import { Employee } from 'features/user/types';
import { ActionButton } from 'components/ActionButton/actionButton';
import { Modal } from 'components/Modal/Modal';
import { UpdateEmployeeForm } from 'components/EmployeeForm/UpdateEmployeeForm/updateEmployeeForm';
import { fetchEmployeeSsnComponent } from 'lib/Check/fetchEmployeeSsnComponent';
import { CheckComponent } from 'components/CheckComponent/checkComponent';
import { ContentContainer } from 'components/SharedComponents/Containers.styles';
import {
  TabContainer,
  TabHeading,
  TabContent,
  SectionHeader,
  ItemContainer,
  ItemLabel,
  ItemValue,
} from '../../../tabMenu.styles';
import { Status } from 'components/Status/status';
import { OnboardingStatus } from '@src/constants/onboardingStatus';
import { FormHeading, SsnModal, CloseButton, StatusSection } from './EmployeeInfo.styles';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { toast } from 'react-toastify';
import { sendOnboardingEmail } from 'lib/sendOnboardingEmail';

type EmployeeInfoProps = {
  employee: Employee;
  updateEmployeeState: () => void;
};

export const EmployeeInfo = ({ employee, updateEmployeeState }: EmployeeInfoProps) => {
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isSSNModalOpen, setSSNModalOpen] = useState(false);
  const [ssnComponentURL, setSsnComponentURL] = useState('');

  const { firstName, lastName, email, dateOfBirth, address, providerIdentifier, ssnLastFour } =
    employee.providerAttributes;
  const fullName = `${firstName} ${lastName}`;

  const handleOpenUpdateModal = () => {
    setUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setUpdateModalOpen(false);
  };

  const handleOpenSSNModal = async () => {
    const url = await fetchEmployeeSsnComponent(employee.id);
    if (url) {
      setSsnComponentURL(url);
    }
    setSSNModalOpen(true);
  };

  const handleCloseSSNModal = () => {
    updateEmployeeState();
    setSSNModalOpen(false);
  };

  const handleCompleteSSNModal = () => {
    updateEmployeeState();
    setSSNModalOpen(false);
  };

  const handleFormSubmit = () => {
    updateEmployeeState();
    handleCloseUpdateModal();
  };

  const handleGenerateOnboardLink = async () => {
    try {
      if (providerIdentifier) {
        await sendOnboardingEmail({ providerIdentifier });
        toast.success('Onboarding link generated and email sent!');
      } else {
        throw new Error('Provider Identifier is missing.');
      }
    } catch (error) {
      toast.error('Error generating onboarding link');
    }
  };

  return (
    <ContentContainer>
      <TabContainer divider>
        <StatusSection>
          <Status
            label="Onboarding Status"
            value={employee.providerAttributes.onboard.status}
            statusType={employee.providerAttributes.onboard.status as OnboardingStatus}
          />
          <ActionButton
            onClick={handleGenerateOnboardLink}
            size="medium"
            title="Generate Onboard Link"
            hidden={false}
          />
        </StatusSection>
        <SectionHeader>
          <TabHeading>General Information</TabHeading>
          <ActionButton
            onClick={handleOpenUpdateModal}
            size="medium"
            title="Edit"
            hidden={false}
            variant="text"
          />
        </SectionHeader>
        <TabContent>
          <ItemContainer>
            <ItemLabel>Full Name</ItemLabel>
            <ItemValue>{fullName}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Email</ItemLabel>
            <ItemValue>{email}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Date of Birth</ItemLabel>
            <ItemValue>{dateOfBirth}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Address</ItemLabel>
            <ItemValue>
              {address ? (
                <>
                  {address.line1}
                  <br />
                  {address.line2 ? (
                    <>
                      {address.line2}
                      <br />
                    </>
                  ) : null}
                  {address.city}, {address.state}
                  <br />
                  {address.postalCode}
                </>
              ) : (
                'Not Available'
              )}
            </ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>Employee ID</ItemLabel>
            <ItemValue>{providerIdentifier}</ItemValue>
          </ItemContainer>
        </TabContent>
      </TabContainer>

      <Modal isOpen={isUpdateModalOpen} onRequestClose={handleCloseUpdateModal}>
        <FormHeading>General Information</FormHeading>
        <UpdateEmployeeForm
          id={employee.id}
          employee={employee}
          handleFormSubmit={handleFormSubmit}
        />
        <CloseButton icon={<CloseIcon />} onClick={handleCloseUpdateModal} />
      </Modal>

      <TabContainer divider>
        <SectionHeader>
          <TabHeading>Social Security Information</TabHeading>
          {ssnLastFour && (
            <ActionButton
              onClick={handleOpenSSNModal}
              size="medium"
              title="Edit"
              hidden={false}
              variant="text"
            />
          )}
        </SectionHeader>
        <TabContent>
          <ItemContainer>
            <ItemLabel>SSN</ItemLabel>
            <ItemValue>{ssnLastFour ? `XXX-XX-${ssnLastFour}` : 'Not Provided'}</ItemValue>
          </ItemContainer>
        </TabContent>
      </TabContainer>

      <SsnModal
        isOpen={isSSNModalOpen}
        onRequestClose={handleCloseSSNModal}
        style={{
          overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 11,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            overflow: 'visible',
            width: '70vw',
            height: '90vh',
          },
        }}
      >
        {isSSNModalOpen && (
          <CheckComponent
            id="ssn-setup"
            componentURL={ssnComponentURL}
            onComplete={handleCompleteSSNModal}
            onClose={handleCloseSSNModal}
          />
        )}
      </SsnModal>

      <TabContainer>
        <SectionHeader>
          <TabHeading>Workplaces</TabHeading>
        </SectionHeader>
        {employee.workplaces.map((workplace) => (
          <TabContent key={workplace.id}>
            <ItemContainer>
              <ItemLabel>Workplace Name</ItemLabel>
              <ItemValue>{workplace.name}</ItemValue>
            </ItemContainer>
            <ItemContainer>
              <ItemLabel>Workplace Address</ItemLabel>
              <ItemValue>
                {workplace.providerAttributes.address ? (
                  <>
                    {workplace.providerAttributes.address.line1}
                    <br />
                    {workplace.providerAttributes.address.city},{' '}
                    {workplace.providerAttributes.address.state}
                    <br />
                    {workplace.providerAttributes.address.postalCode}
                  </>
                ) : (
                  'Not Available'
                )}
              </ItemValue>
            </ItemContainer>
            <ItemContainer>
              <ItemLabel>Workplace ID</ItemLabel>
              <ItemValue>{workplace.providerIdentifier}</ItemValue>
            </ItemContainer>
          </TabContent>
        ))}
      </TabContainer>
    </ContentContainer>
  );
};
