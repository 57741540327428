import { MouseEvent, MouseEventHandler } from 'react';
import { Icon } from 'components/Icon/icon';
import { IconType } from '../../assets/icons/index';

import { ActionButtonBase, ActionButtonTitle } from './actionButton.styles';

export type ActionButtonSize = 'small' | 'medium' | 'large';

export type ActionButtonShape = 'square' | 'circle' | 'fullWidth';

export type ActionButtonType = 'filled' | 'outline' | 'text';

type ActionButtonProps = {
  size?: ActionButtonSize;
  variant?: ActionButtonType;
  title?: string;
  iconName?: string;
  color?: string;
  disabled?: boolean;
  shape?: ActionButtonShape;
  hidden?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  'data-cy'?: string;
};

export const ActionButton = (props: ActionButtonProps) => {
  const {
    title,
    iconName,
    size,
    variant,
    color,
    disabled,
    hidden,
    shape,
    onClick,
    'data-cy': dataCY,
  } = props;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  if (hidden || (!iconName && !title)) {
    return null;
  }

  return (
    <ActionButtonBase
      shape={shape}
      size={size}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      data-cy={dataCY}
    >
      {iconName && <Icon name={iconName as IconType} size={size} color={color} />}
      {title && <ActionButtonTitle>{title}</ActionButtonTitle>}
    </ActionButtonBase>
  );
};

ActionButton.defaultProps = {
  size: 'small',
  variant: 'filled',
};
