import { useEffect, useState, useCallback } from 'react';
import {
  HeadingContainer,
  Heading,
  Instruction,
  ModalHeading,
  ModalMessage,
  ModalImage,
} from './ReviewStep.styles';
import { ButtonContainer } from '../RunPayrollTables.styles';
import { previewDraftPayroll, PayrollDetails } from 'lib/previewDraftPayroll';
import { EmployeeHourlyBreakdown } from './EmployeeHourlyBreakdown';
import { EmployeePayBreakdown } from './EmployeePayBreakdown';
import { TaxBreakdown } from './TaxBreakdown';
import { toast } from 'react-toastify';
import { approvePayroll } from 'lib/approvePayroll';
import ReactModal from 'react-modal';
import { ActionButton } from 'components/ActionButton/actionButton';
import { useNavigate } from 'react-router-dom';
import successImage from '../../../../assets/images/success.png';
type ReviewStepProps = {
  payrollProviderId: string;
  onSubmit: (submitFunc: () => void) => void;
};

export const ReviewStep = ({ payrollProviderId, onSubmit }: ReviewStepProps) => {
  const [payrollDetails, setPayrollDetails] = useState<PayrollDetails | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    previewDraftPayroll(payrollProviderId)
      .then((data: PayrollDetails | void) => {
        if (data) {
          setPayrollDetails(data);
        }
      })
      .catch((error) => {
        toast.error('Failed to fetch payroll data.');
      });
  }, [payrollProviderId]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const response = await approvePayroll({ providerId: payrollProviderId });
      if (response) {
        setShowSuccessModal(true);
      } else {
        toast.error('Failed to approve payroll.');
      }
    } catch (error) {
      toast.error('An error occurred during payroll approval.');
    } finally {
      setIsSubmitting(false);
    }
  }, [payrollProviderId]);

  useEffect(() => {
    onSubmit(() => {
      handleSubmit();
    });
  }, [onSubmit, handleSubmit]);

  return (
    <div>
      <HeadingContainer>
        <Heading>Payroll Summary</Heading>
        <Instruction>
          Below you will see an extensive summary of your employees’ pay hours, and tax breakdown.
          We will debit your account once you submit this payroll.
        </Instruction>
      </HeadingContainer>
      {payrollDetails ? (
        <>
          <TaxBreakdown items={payrollDetails.items} />
          <EmployeePayBreakdown items={payrollDetails.items} />
          <EmployeeHourlyBreakdown items={payrollDetails.items} />
        </>
      ) : (
        ''
      )}
      <ReactModal
        isOpen={showSuccessModal}
        onRequestClose={() => setShowSuccessModal(false)}
        style={{
          overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 11,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            overflow: 'visible',
            textAlign: 'center',
            padding: '0px',
          },
        }}
      >
        <ModalHeading>Payroll Submitted!</ModalHeading>
        <ModalMessage>Your payroll has been successfully submitted!</ModalMessage>
        <ModalImage src={successImage} alt="Success" />
        <ButtonContainer>
          <ActionButton
            onClick={() => navigate('/payroll')}
            size="medium"
            title="Back to Payroll Dashboard"
          />
        </ButtonContainer>
      </ReactModal>
    </div>
  );
};
