import { procareApi, ErrorResponse } from 'lib/HttpClient/httpClient';
import { AxiosResponse, AxiosError } from 'axios';
import { EmployeeInput } from 'components/EmployeeForm/types';
import { APIError, sendErrorMessage } from './sendErrorMessage';

type Employee = {
  name: string;
};

type CreateEmployeePayload = {
  employee: Employee;
  error: {
    message: string;
    type: string;
    inputErrors: {
      message: string;
      field: string;
      fieldPath: string;
    }[];
  };
};

export const createEmployee = (employeeParams: EmployeeInput): Promise<Employee | void> => {
  const mutation = `
        mutation($employeeParams: CreateEmployeeInput!) {
          createEmployee(employeeParams: $employeeParams) {
            employee {
              name
            }
            error {
              message
              type
              inputErrors {
                field
                fieldPath
                message
              }
            }
          }
        }
      `;

  const variables = {
    employeeParams,
  };

  return procareApi
    .post<unknown, AxiosResponse<{ data: { createEmployee: CreateEmployeePayload } }>>('graphql', {
      query: mutation,
      variables,
    })
    .then((response: AxiosResponse<{ data: { createEmployee: CreateEmployeePayload } }>) => {
      if (response.data.data.createEmployee.error) {
        sendErrorMessage(response.data.data.createEmployee.error);
      }
      return response.data.data.createEmployee.employee;
    })
    .catch((error: AxiosError<APIError>) => {
      sendErrorMessage(error);
    });
};
