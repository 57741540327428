import { MouseEventHandler } from 'react';
import { ActionButtonSize } from 'components/ActionButton/actionButton';
import { IconContainer } from './icon.styles';
import { iconsMap, IconType } from '../../assets/icons/index';

type IconProps = {
  name: IconType;
  color?: string;
  size?: ActionButtonSize;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  'data-cy'?: string;
};

export const Icon = (props: IconProps) => {
  const { name, color, size, onClick, 'data-cy': dataCY } = props;
  const IconSvg = iconsMap[name];

  return (
    <IconContainer size={size} color={color} onClick={onClick} data-cy={dataCY}>
      <IconSvg />
    </IconContainer>
  );
};

Icon.defaultProps = {
  size: 'small',
};
