import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as PeopleIcon } from './people.svg';
import { ReactComponent as PayrollIcon } from './payroll.svg';
import { ReactComponent as ReportsIcon } from './reports.svg';
import { ReactComponent as DocumentsIcon } from './documents.svg';
import { ReactComponent as CompanyIcon } from './company.svg';
import { ReactComponent as ProfileIcon } from './profile.svg';
import { ReactComponent as SyncsIcon } from './syncs.svg';
import { ReactComponent as FilterIcon } from './filter.svg';

export const iconsMap = {
  dashboard: DashboardIcon,
  people: PeopleIcon,
  payroll: PayrollIcon,
  reports: ReportsIcon,
  documents: DocumentsIcon,
  company: CompanyIcon,
  paystubs: ReportsIcon,
  info: CompanyIcon,
  profile: ProfileIcon,
  syncs: SyncsIcon,
  filter: FilterIcon,
};

export type IconType = keyof typeof iconsMap;
