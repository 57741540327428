import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Table } from './Table';
import { DraftsTable } from './DraftsTable';
import { fetchCompanyPayrolls, Payroll, PageInfo, PayrollStatus } from 'lib/fetchCompanyPayrolls';
import { Spinner } from 'components/Spinner/spinner';
import { Pagination } from 'components/Pagination/pagination';
import { DEFAULT_PAGE_SIZE } from '@src/constants/constants';
import { Filter } from 'components/Filter/Filter';
import { PayrollStatusFilters } from 'components/Filter/Filters/PayrollStatusFilters/PayrollStatusFilters';
import { CurrentUserContext } from 'features/user/types';
import { ControlBar } from '../Table.styles';

type UserState = {
  user: {
    currentUser: CurrentUserContext | null;
  };
};

type PayrollsTableProps = {
  refresh?: boolean;
  onOpenPayrollModal?: () => void;
  status?: PayrollStatus;
  pageSize?: number;
  disablePagination?: boolean;
};

export const PayrollsTable = ({
  refresh = false,
  onOpenPayrollModal,
  status,
  pageSize = DEFAULT_PAGE_SIZE,
  disablePagination = false,
}: PayrollsTableProps) => {
  const { currentUser } = useAppSelector((state: UserState) => state.user);
  const [payrolls, setPayrolls] = useState<Payroll[]>([]);
  const [loading, setLoading] = useState(true);
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);

  const [selectedStatus, setSelectedStatus] = useState<PayrollStatus | undefined>(status);

  useEffect(() => {
    setLoading(true);
    fetchCompanyPayrolls(endCursor, { status: selectedStatus }, pageSize).then(
      ({ payrolls: newPayrolls, pageInfo: newPageInfo }) => {
        setPayrolls(newPayrolls);
        setPageInfo(newPageInfo);
        setLoading(false);
      }
    );
  }, [currentUser, endCursor, refresh, selectedStatus, pageSize]);

  const loadMore = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      setEndCursor(pageInfo.endCursor);
    }
  };

  const onPrev = () => {
    if (pageInfo && pageInfo.hasPreviousPage) {
      setEndCursor(pageInfo.startCursor);
    }
  };

  const onNext = () => {
    if (pageInfo && pageInfo.hasNextPage) {
      loadMore();
    }
  };

  const renderTable = () => {
    if (selectedStatus === PayrollStatus.Draft) {
      return <DraftsTable payrolls={payrolls} />;
    } else {
      return <Table payrolls={payrolls} onOpenPayrollModal={onOpenPayrollModal} />;
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <ControlBar>
        {status && status !== PayrollStatus.Draft && (
          <Filter
            tooltipContent={
              <PayrollStatusFilters onApplyFilter={setSelectedStatus} initialStatus={status} />
            }
          />
        )}
        {!disablePagination && pageInfo && (pageInfo.hasPreviousPage || pageInfo.hasNextPage) && (
          <Pagination
            onPrev={onPrev}
            onNext={onNext}
            disablePrev={!pageInfo.hasPreviousPage}
            disableNext={!pageInfo.hasNextPage}
          />
        )}
      </ControlBar>
      {renderTable()}
    </>
  );
};
